import React from 'react';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesFilters
} from '../../../../../lifestylesTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { LifestylesIndexPageCategoriesNav } from '../../../../menus/LifestylesIndexPageCategoriesNav';
import { LifestylesStylesFilter } from '../../../../filters/LifestylesStylesFilter';
import { LifestylesMaterialsFilter } from '../../../../filters/LifestylesMaterialsFilter';
import { LifestylesColorsFilter } from '../../../../filters/LifestylesColorsFilter';
import { LifestylesNdaFilter } from '../../../../filters/LifestylesNdaFilter';
import { LifestylesPremiumContentAtFilter } from '../../../../filters/LifestylesPremiumContentAtFilter';
import { LifestylesAuthorsFilter } from '../../../../filters/LifestylesAuthorsFilter';
import { LifestylesGammasFilter } from '../../../../filters/LifestylesGammasFilter';
import { LifestylesTonesFilter } from '../../../../filters/LifestylesTonesFilter';
import { LifestylesBlockedFilter } from '../../../../filters/LifestylesBlockedFilter';
import { LifestylesClientFilter } from '../../../../filters/LifestylesClientFilter';
import { LifestylesFavoriteCheckboxFilter } from '../../../../filters/LifestylesFavoriteCheckboxFilter';
import { LifestylesMyLibraryFilter } from '../../../../filters/LifestylesMyLibraryFilter';

import { LifestylesPermissions } from '../../../../../lifestylesConstants';

interface SelectLifestylesContentFiltersSidebarProps {
  lifestylesFilters: FetchLifestylesFilters;
  withPremiumContentAtFilter?: boolean;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
  onClose: () => void;
}

function SelectLifestylesContentFiltersSidebar({
  lifestylesFilters,
  withPremiumContentAtFilter,
  changeLifestylesFilters,
  onClose
}: SelectLifestylesContentFiltersSidebarProps) {
  return (
    <div className="flex flex-col w-80 4xl:w-112 absolute xl:relative bg-white dark:bg-gray-850 border-r dark:border-gray-700 left-0 top-0 bottom-0 max-h-full max-w-full z-10">
      <div className="flex flex-col max-h-full flex-1 overflow-y-auto py-4">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div id="nav-3d-stock">
          <LifestylesIndexPageCategoriesNav
            lifestylesFilters={lifestylesFilters}
            changeLifestylesFilters={changeLifestylesFilters}
          />

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_MY_LIBRARY_FILTER}
          >
            <LifestylesMyLibraryFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          {withPremiumContentAtFilter ? (
            <LifestylesPremiumContentAtFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          ) : null}

          <CheckPermissions
            action={
              LifestylesPermissions.READ_LIFESTYLES_FAVORITE_CHECKBOX_FILTER
            }
          >
            <LifestylesFavoriteCheckboxFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_NDA_FILTER}
          >
            <LifestylesNdaFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_CLIENTS_FILTER}
          >
            <LifestylesClientFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <LifestylesStylesFilter
            lifestylesFilters={lifestylesFilters}
            changeLifestylesFilters={changeLifestylesFilters}
          />

          <LifestylesGammasFilter
            lifestylesFilters={lifestylesFilters}
            changeLifestylesFilters={changeLifestylesFilters}
          />

          <LifestylesTonesFilter
            lifestylesFilters={lifestylesFilters}
            changeLifestylesFilters={changeLifestylesFilters}
          />

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_MATERIALS_FILTER}
          >
            <LifestylesMaterialsFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_COLORS_FILTER}
          >
            <LifestylesColorsFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_AUTHORS_FILTER}
          >
            <LifestylesAuthorsFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLES_BLOCKED_FILTER}
          >
            <LifestylesBlockedFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>
        </div>
      </div>
    </div>
  );
}

export default SelectLifestylesContentFiltersSidebar;
