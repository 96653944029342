import React, { useCallback, useState, ReactNode } from 'react';
import keys from 'lodash/keys';
import size from 'lodash/size';
import omit from 'lodash/omit';

import { SimpleModalButton } from '../../buttons/SimpleModalButton';
import { AlertMessage } from '../../AlertMessage';

import {
  DropzoneHelper,
  DropzoneHelperFiles,
  DropzoneHelperFileIds,
  DropzoneHelperRequiredProps
} from '../DropzoneHelper';

import { DropzoneModalButtonRequiredProps } from './DropzoneModalButton.types';
import { ErrorMessage, I18nText } from '../../../types';

interface DropzoneModalButtonBaseProps {
  value: string[];
  beforeDropzone?: ReactNode;
  disabled?: boolean;
  submitErrorMessage?: ErrorMessage;
  resetOnClose?: boolean;
  maxFiles?: number;
  preventMaxFilesOverload?: boolean;
}

interface DropzoneModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface DropzoneModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

interface DropzoneModalButtonWithOnSubmitProps {
  onSubmit: (value: string[]) => Promise<unknown>;
  onChange?: never;
}

interface DropzoneModalButtonWithOnChangeProps {
  onSubmit?: never;
  onChange: (value: string[]) => void;
}

type DropzoneModalButtonProps = DropzoneModalButtonBaseProps &
  (DropzoneModalButtonWithTextProps | DropzoneModalButtonWithI18nTextProps) &
  (DropzoneModalButtonWithOnChangeProps | DropzoneModalButtonWithOnSubmitProps);

const initialFiles = {};

function DropzoneModalButton({
  beforeDropzone,
  buttonText,
  className,
  disabled,
  i18nSubmitText,
  i18nText,
  i18nTitle,
  icon,
  iconClassName,
  maxFiles,
  modalIcon,
  onChange,
  onSubmit,
  preventMaxFilesOverload,
  resetOnClose,
  submitDisabled,
  submitErrorMessage,
  tooltipI18nText,
  tooltipPlacement,
  type,
  value,
  withFullscreenDropzone,
  withoutDropzoneArea,
  withoutTabs
}: DropzoneModalButtonProps &
  DropzoneModalButtonRequiredProps &
  DropzoneHelperRequiredProps) {
  const [uploadedFiles, setUploadedFiles] =
    useState<DropzoneHelperFiles>(initialFiles);
  const [uploadedFilesBeforeOpen, setUploadedFilesBeforeOpen] =
    useState<DropzoneHelperFiles>(initialFiles);
  const [uploadedFileIds, setUploadedFileIds] =
    useState<DropzoneHelperFileIds>(value);
  const [uploadedFileIdsBeforeOpen, setUploadedFileIdsBeforeOpen] =
    useState<DropzoneHelperFileIds>(value);

  const handleOpen = useCallback<() => void>(() => {
    setUploadedFilesBeforeOpen(uploadedFiles);
    setUploadedFileIdsBeforeOpen(uploadedFileIds);
  }, [
    uploadedFiles,
    uploadedFileIds,
    setUploadedFilesBeforeOpen,
    setUploadedFileIdsBeforeOpen
  ]);

  const handleCancel = useCallback<() => void>(() => {
    setUploadedFiles(uploadedFilesBeforeOpen);
    setUploadedFileIds(uploadedFileIdsBeforeOpen);
  }, [uploadedFilesBeforeOpen, uploadedFileIdsBeforeOpen]);

  const handleClose = useCallback<() => void>(() => {
    const processingFileIds = keys(uploadedFiles).filter(
      (key) => uploadedFiles[key].state === 'processing'
    );

    if (size(processingFileIds) > 0) {
      setUploadedFiles((prevState) =>
        omit<DropzoneHelperFiles>(prevState, processingFileIds)
      );
    }
  }, [uploadedFiles]);

  const handleAfterSubmit = useCallback<() => void>(() => {
    if (resetOnClose) {
      setUploadedFiles(initialFiles);
      setUploadedFileIds([]);
    } else {
      setUploadedFilesBeforeOpen(uploadedFiles);
      setUploadedFileIdsBeforeOpen(uploadedFileIds);
    }
  }, [resetOnClose, uploadedFileIds, uploadedFiles]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (onChange) {
      onChange(uploadedFileIds);
      handleAfterSubmit();
    } else {
      return onSubmit?.(uploadedFileIds).then(() => handleAfterSubmit());
    }
  }, [onChange, uploadedFileIds, handleAfterSubmit, onSubmit]);

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 overflow-y-auto p-4"
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={modalIcon}
      {...(i18nText ? { i18nText } : { buttonText })}
      i18nSubmitText={i18nSubmitText}
      disabled={
        size(
          keys(uploadedFiles).filter(
            (key) => uploadedFiles[key].state === 'processing'
          )
        ) > 0
      }
      submitDisabled={submitDisabled || size(uploadedFileIds) < 1}
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      {beforeDropzone}
      <div className="mt-5">
        <AlertMessage addClassName="m-4" message={submitErrorMessage} />
        <DropzoneHelper
          type={type}
          initialFiles={uploadedFiles}
          initialFileIds={uploadedFileIds}
          disabled={disabled}
          withFullscreenDropzone={withFullscreenDropzone}
          onChange={setUploadedFileIds}
          onChangeFiles={setUploadedFiles}
          withoutTabs={withoutTabs}
          withoutDropzoneArea={withoutDropzoneArea}
          maxFiles={maxFiles}
          preventMaxFilesOverload={preventMaxFilesOverload}
        />
      </div>
    </SimpleModalButton>
  );
}

export default DropzoneModalButton;
