import React, { Fragment, useCallback } from 'react';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesClearLifestylesFilters,
  FetchLifestylesFilterLifestyles,
  FetchLifestylesFilters,
  FetchLifestylesTotalCount
} from '../../../../../lifestylesTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { LifestylesAppliedFilters } from '../../../../LifestylesAppliedFilters';
import { LifestylesIndexPageBreadcrumbs } from '../../../../LifestylesIndexPageBreadcrumbs';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { LifestylesFavoriteButtonFilter } from '../../../../filters/LifestylesFavoriteButtonFilter';

import { lifestylesKeys, words } from '../../../../../../../locales/keys';
import { LifestylesPermissions } from '../../../../../lifestylesConstants';

interface SelectLifestylesContentHeaderProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
  showFiltersButton: boolean;
  onFiltersOpen: () => void;
  clearLifestylesFilters: FetchLifestylesClearLifestylesFilters;
  filterLifestyles: FetchLifestylesFilterLifestyles;
  lifestylesTotalCount?: FetchLifestylesTotalCount;
}

function SelectLifestylesContentHeader({
  lifestylesFilters,
  changeLifestylesFilters,
  showFiltersButton,
  onFiltersOpen,
  clearLifestylesFilters,
  filterLifestyles,
  lifestylesTotalCount
}: SelectLifestylesContentHeaderProps) {
  const handleSearch = useCallback<
    (
      changedFilters: { [name: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ name }, removeFilters) =>
      changeLifestylesFilters({ name: { ilike: name } }, removeFilters),
    [changeLifestylesFilters]
  );

  return (
    <Fragment>
      <div className="flex sticky top-0 py-2 -my-2 z-10 bg-white dark:bg-gray-850 px-4 gap-2 items-center">
        {showFiltersButton ? (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            icon={IconsEnum.FILTER}
            i18nTextClassName="sr-only sm:not-sr-only"
            i18nText={words.filter}
            onClick={onFiltersOpen}
          />
        ) : null}

        <div className="flex items-center w-full max-w-lg">
          <TextFilter
            className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
            inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
            name="name"
            value={lifestylesFilters.name?.ilike || ''}
            i18nPlaceholder={lifestylesKeys.search}
            autoComplete="off"
            type="search"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
        <LifestylesIndexPageBreadcrumbs
          changeLifestylesFilters={changeLifestylesFilters}
          lifestylesFilters={lifestylesFilters}
          lifestylesTotalCount={lifestylesTotalCount}
          withoutStockLink
        />

        <div className="flex justify-end mt-4 sm:mt-0 space-x-1">
          <CheckPermissions
            action={
              LifestylesPermissions.READ_LIFESTYLES_FAVORITE_BUTTON_FILTER
            }
          >
            <LifestylesFavoriteButtonFilter
              lifestylesFilters={lifestylesFilters}
              changeLifestylesFilters={changeLifestylesFilters}
            />
          </CheckPermissions>
        </div>
      </div>

      <LifestylesAppliedFilters
        lifestylesFilters={lifestylesFilters}
        clearLifestylesFilters={clearLifestylesFilters}
        filterLifestyles={filterLifestyles}
      />
    </Fragment>
  );
}

export default SelectLifestylesContentHeader;
