import React from 'react';

import {
  ErrorMessage,
  IsFetched,
  IsPlaceholderData
} from '../../../../../../../types';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMaterialsCacheKeys } from '../../../../../../materials/materialsTypes';
import { FetchMaterialsSetsCacheKeys } from '../../../../../../materialsSets/materialsSetsTypes';
import { FetchSelectedMaterialsTotalCount } from '../../../../../../selectedMaterials/selectedMaterialsTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';

import {
  SelectedMaterialsList,
  SelectedMaterialsListMaterialsSet,
  SelectedMaterialsListUpdateMaterialsSetCache
} from '../../../../../../selectedMaterials/components/lists/SelectedMaterialsList';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { materialsKeys } from '../../../../../../../locales/keys';

interface SelectMaterialsContentSidebarProps {
  materialsSet: SelectedMaterialsListMaterialsSet;
  materialsSetFetched: IsFetched;
  materialsSetError: ErrorMessage;
  materialsSetIsPlaceholderData: IsPlaceholderData;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  updateMaterialsSetCache: SelectedMaterialsListUpdateMaterialsSetCache;
  togglePreventModalClose: TogglePreventModalCloseAction;
  onClose: () => void;
  selectedMaterialsCount?: FetchSelectedMaterialsTotalCount;
}

function SelectMaterialsContentSidebar({
  materialsSet,
  materialsSetFetched,
  materialsSetError,
  materialsSetIsPlaceholderData,
  materialsCacheKeys,
  materialsSetCacheKeys,
  updateMaterialsSetCache,
  togglePreventModalClose,
  onClose,
  selectedMaterialsCount
}: SelectMaterialsContentSidebarProps) {
  return (
    <aside
      id="side-panel"
      className="pb-10 sm:pb-0 top-0 right-0 xl:relative shrink xl:w-auto z-10"
    >
      <div className="flex flex-col w-80 4xl:w-112 absolute xl:relative bg-white dark:bg-gray-850 border-l dark:border-gray-700 right-0 top-0 bottom-0 max-h-full max-w-full z-10">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div className="p-4">
          <h3 className="font-semibold break-words text-lg leading-snug flex items-center gap-2">
            <Translate id={materialsKeys.selected} />
            <span className="py-0.5 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600">
              {selectedMaterialsCount}
            </span>
          </h3>
        </div>
        <div className="p-4 overflow-y-auto">
          <SelectedMaterialsList
            materialsSet={materialsSet}
            materialsSetFetched={materialsSetFetched}
            materialsSetError={materialsSetError}
            materialsSetIsPlaceholderData={materialsSetIsPlaceholderData}
            materialsCacheKeys={materialsCacheKeys}
            materialsSetCacheKeys={materialsSetCacheKeys}
            onSelectedMaterialsSidebarClose={onClose}
            updateMaterialsSetCache={updateMaterialsSetCache}
            togglePreventModalClose={togglePreventModalClose}
          />
        </div>
      </div>
      <div
        id="side-panel-backdrop"
        className="absolute inset-0 z-0 bg-gray-200 bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-50 xl:hidden"
        onClick={onClose}
      />
    </aside>
  );
}

export default SelectMaterialsContentSidebar;
