import React, { Fragment, useCallback } from 'react';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsClearMaterialsFilters,
  FetchMaterialsFilterMaterials,
  FetchMaterialsFilters,
  FetchMaterialsSort,
  FetchMaterialsTotalCount
} from '../../../../../materialsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MaterialsSortDropdownButton } from '../../../../buttons/MaterialsSortDropdownButton';
import { MaterialsFavoriteButtonFilter } from '../../../../filters/MaterialsFavoriteButtonFilter';
import { MaterialsAppliedFilters } from '../../../../MaterialsAppliedFilters';
import { MaterialsIndexPageBreadcrumbs } from '../../../../MaterialsIndexPageBreadcrumbs';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MaterialCache } from '../../../../../MaterialCache';
import { materialsKeys, words } from '../../../../../../../locales/keys';
import { MaterialsPermissions } from '../../../../../materialsConstants';

interface SelectMaterialsContentHeaderProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
  showFiltersButton: boolean;
  onFiltersOpen: () => void;
  clearMaterialsFilters: FetchMaterialsClearMaterialsFilters;
  filterMaterials: FetchMaterialsFilterMaterials;
  currentSort: FetchMaterialsSort;
  sortMaterials: (nextSort: FetchMaterialsSort) => void;
  materialsTotalCount?: FetchMaterialsTotalCount;
}

function SelectMaterialsContentHeader({
  materialsFilters,
  changeMaterialsFilters,
  showFiltersButton,
  onFiltersOpen,
  clearMaterialsFilters,
  filterMaterials,
  currentSort,
  sortMaterials,
  materialsTotalCount
}: SelectMaterialsContentHeaderProps) {
  const handleSearch = useCallback<
    (
      changedFilters: { [name: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ name }, removeFilters) =>
      changeMaterialsFilters({ name: { ilike: name } }, removeFilters),
    [changeMaterialsFilters]
  );

  return (
    <Fragment>
      <div className="flex sticky top-0 py-2 -my-2 z-10 bg-white dark:bg-gray-850 px-4 gap-2 items-center">
        {showFiltersButton ? (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            icon={IconsEnum.FILTER}
            i18nTextClassName="sr-only sm:not-sr-only"
            i18nText={words.filter}
            onClick={onFiltersOpen}
          />
        ) : null}

        <div className="flex items-center w-full max-w-lg">
          <TextFilter
            className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
            inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
            name="name"
            value={materialsFilters.name?.ilike || ''}
            i18nPlaceholder={materialsKeys.name}
            autoComplete="off"
            type="search"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
        <MaterialsIndexPageBreadcrumbs
          changeMaterialsFilters={changeMaterialsFilters}
          materialsFilters={materialsFilters}
          materialsTotalCount={materialsTotalCount}
          withoutStockLink
        />

        <div className="flex justify-end mt-4 sm:mt-0 space-x-1">
          <MaterialsSortDropdownButton
            currentSort={currentSort}
            materialsBaseCacheKey={MaterialCache.indexCacheKey()}
            sortMaterials={sortMaterials}
          />
          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIALS_FAVORITE_BUTTON_FILTER}
          >
            <MaterialsFavoriteButtonFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>
        </div>
      </div>

      <MaterialsAppliedFilters
        materialsFilters={materialsFilters}
        clearMaterialsFilters={clearMaterialsFilters}
        filterMaterials={filterMaterials}
      />
    </Fragment>
  );
}

export default SelectMaterialsContentHeader;
