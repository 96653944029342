import React from 'react';

import {
  ErrorMessage,
  IsFetched,
  IsPlaceholderData
} from '../../../../../../../types';

import { FetchProductsCacheKeys } from '../../../../../../products/productsTypes';
import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';
import { FetchSelectedProductsTotalCount } from '../../../../../../selectedProducts/selectedProductsTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';

import {
  SelectedProductsList,
  SelectedProductsListProductsSet,
  SelectedProductsListUpdateProductsSetCache
} from '../../../../../../selectedProducts/components/lists/SelectedProductsList';

import { SelectedLibraryItemsModalSidebar } from '../../../../../../common/components/sidebars/SelectedLibraryItemsModalSidebar';

import { productsKeys } from '../../../../../../../locales/keys';

interface SelectProductsContentSidebarProps {
  productsSet: SelectedProductsListProductsSet;
  productsSetFetched: IsFetched;
  productsSetError: ErrorMessage;
  productsSetIsPlaceholderData: IsPlaceholderData;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductsSetCache: SelectedProductsListUpdateProductsSetCache;
  togglePreventModalClose: TogglePreventModalCloseAction;
  onClose: () => void;
  selectedProductsCount?: FetchSelectedProductsTotalCount;
}

function SelectProductsContentSidebar({
  productsSet,
  productsSetFetched,
  productsSetError,
  productsSetIsPlaceholderData,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductsSetCache,
  togglePreventModalClose,
  onClose,
  selectedProductsCount
}: SelectProductsContentSidebarProps) {
  return (
    <SelectedLibraryItemsModalSidebar
      i18nTitle={productsKeys.selected}
      onClose={onClose}
      selectedItemsCount={selectedProductsCount}
    >
      <SelectedProductsList
        productsSet={productsSet}
        productsSetFetched={productsSetFetched}
        productsSetError={productsSetError}
        productsSetIsPlaceholderData={productsSetIsPlaceholderData}
        productsCacheKeys={productsCacheKeys}
        productsSetCacheKeys={productsSetCacheKeys}
        onSelectedProductsSidebarClose={onClose}
        updateProductsSetCache={updateProductsSetCache}
        togglePreventModalClose={togglePreventModalClose}
      />
    </SelectedLibraryItemsModalSidebar>
  );
}

export default SelectProductsContentSidebar;
