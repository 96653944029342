import React from 'react';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsFilters
} from '../../../../../materialsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MaterialsIndexPageCategoriesNav } from '../../../../menus/MaterialsIndexPageCategoriesNav';
import { MaterialsBrandsFilter } from '../../../../filters/MaterialsBrandsFilter';
import { MaterialsNdaFilter } from '../../../../filters/MaterialsNdaFilter';
import { MaterialsClientFilter } from '../../../../filters/MaterialsClientFilter';
import { MaterialsBlockedFilter } from '../../../../filters/MaterialsBlockedFilter';
import { MaterialsMyLibraryFilter } from '../../../../filters/MaterialsMyLibraryFilter';
import { MaterialsFavoriteCheckboxFilter } from '../../../../filters/MaterialsFavoriteCheckboxFilter';

import { MaterialsPermissions } from '../../../../../materialsConstants';

interface SelectMaterialsContentFiltersSidebarProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
  onClose: () => void;
}

function SelectMaterialsContentFiltersSidebar({
  materialsFilters,
  changeMaterialsFilters,
  onClose
}: SelectMaterialsContentFiltersSidebarProps) {
  return (
    <div className="flex flex-col w-80 4xl:w-112 absolute xl:relative bg-white dark:bg-gray-850 border-r dark:border-gray-700 left-0 top-0 bottom-0 max-h-full max-w-full z-10">
      <div className="flex flex-col max-h-full flex-1 overflow-y-auto py-4">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div id="nav-3d-stock">
          <MaterialsIndexPageCategoriesNav
            changeMaterialsFilters={changeMaterialsFilters}
            materialsFilters={materialsFilters}
          />

          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIALS_MY_LIBRARY_FILTER}
          >
            <MaterialsMyLibraryFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              MaterialsPermissions.READ_MATERIALS_FAVORITE_CHECKBOX_FILTER
            }
          >
            <MaterialsFavoriteCheckboxFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIALS_NDA_FILTER}
          >
            <MaterialsNdaFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIALS_CLIENTS_FILTER}
          >
            <MaterialsClientFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIALS_BLOCKED_FILTER}
          >
            <MaterialsBlockedFilter
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </CheckPermissions>

          <MaterialsBrandsFilter
            materialsFilters={materialsFilters}
            changeMaterialsFilters={changeMaterialsFilters}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectMaterialsContentFiltersSidebar;
