import React, { Fragment, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesLimit
} from '../../../lifestylesTypes';
import { SelectLifestylesContentOnLifestylesSelect } from './SelectLifestylesContent.types';
import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  FETCH_LIFESTYLES_QUERY,
  FetchLifestylesQueryResponse
} from '../../../queries/fetchLifestyles.query';
import {
  FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
  FetchLifestylesSetByUserIdQueryResponse
} from '../../../../lifestylesSets/queries/fetchLifestylesSetByUserId.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useMainScroll } from '../../../../../app/hooks/useMainScroll';
import { useLifestylesSetByUserId } from '../../../../lifestylesSets/hooks/useLifestylesSetByUserId';
import { useLifestyles } from '../../../hooks/useLifestyles';

import { SelectedLifestylesFloatingCounter } from '../../../../selectedLifestyles/components/SelectedLifestylesFloatingCounter';
import { LifestylesList } from '../../lists/LifestylesList';
import { SelectLifestylesContentFiltersSidebar } from './components/SelectLifestylesContentFiltersSidebar';
import { SelectLifestylesContentHeader } from './components/SelectLifestylesContentHeader';
import { SelectLifestylesContentSidebar } from './components/SelectLifestylesContentSidebar';

import { NoResults } from '../../../../../helpers/NoResults';
import { MainScrollWrapper } from '../../../../common/helpers/MainScrollWrapper';
import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';

import { LifestylesSetCache } from '../../../../lifestylesSets/LifestylesSetCache';
import { LifestyleCache } from '../../../LifestyleCache';
import { LifestylesPermissions } from '../../../lifestylesConstants';

import { words } from '../../../../../locales/keys';

const lifestylesCacheKey = LifestyleCache.indexCacheKey();
const lifestylesCacheKeys = [lifestylesCacheKey];
const lifestylesSetCacheKey = LifestylesSetCache.showCacheKey();
const lifestylesSetCacheKeys = [lifestylesSetCacheKey];

interface SelectLifestylesContentProps {
  initialLimit: FetchLifestylesLimit;
  withPremiumContentAtFilter?: boolean;
  onLifestylesSelect: SelectLifestylesContentOnLifestylesSelect;
  togglePreventModalClose: TogglePreventModalCloseAction;
}

function SelectLifestylesContent({
  initialLimit,
  withPremiumContentAtFilter,
  onLifestylesSelect,
  togglePreventModalClose
}: SelectLifestylesContentProps) {
  const {
    lifestyles,
    lifestylesError,
    lifestylesTotalCount,
    lifestylesFetched,
    lifestylesFetchingNextPage,
    lifestylesFilters,
    lifestylesIsPlaceholderData,
    hasNextLifestylesPage,
    updateLifestyleCache,
    loadMoreLifestyles,
    changeLifestylesFilters,
    clearLifestylesFiltersPersistInitial,
    filterLifestyles,
    lifestylesPage,
    lifestylesLimit
  } = useLifestyles<FetchLifestylesQueryResponse>({
    cacheKey: lifestylesCacheKey,
    query: FETCH_LIFESTYLES_QUERY,
    initialLimit,
    initialFilters: {
      nda: false
    }
  });

  const currentUser = useCurrentUser();

  const {
    lifestylesSet,
    lifestylesSetError,
    lifestylesSetFetched,
    lifestylesSetIsPlaceholderData,
    updateLifestylesSetCache
  } = useLifestylesSetByUserId<FetchLifestylesSetByUserIdQueryResponse>({
    cacheKey: LifestylesSetCache.showCacheKey(),
    query: FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
    userId: currentUser.get('id'),
    options: {
      onSuccess: (data) =>
        data?.lifestylesSetByUserId?.selectedLifestyles
          ? onLifestylesSelect?.(
              data?.lifestylesSetByUserId?.selectedLifestyles
            )
          : undefined
    }
  });

  const { scrollTop } = useMainScroll();

  const handleChangeLifestylesFilters =
    useCallback<ChangeLifestylesFiltersFunc>(
      (changedFilters, removeFilters) => {
        scrollTop();

        return changeLifestylesFilters(changedFilters, removeFilters);
      },
      [changeLifestylesFilters, scrollTop]
    );

  const [isSelectedLifestylesSidebarOpen, setIsSelectedLifestylesSidebarOpen] =
    useState(false);

  const handleSelectedLifestylesSidebarOpen = useCallback<() => void>(
    () => setIsSelectedLifestylesSidebarOpen(true),
    [setIsSelectedLifestylesSidebarOpen]
  );

  const handleSelectedLifestylesSidebarClose = useCallback<() => void>(
    () => setIsSelectedLifestylesSidebarOpen(false),
    [setIsSelectedLifestylesSidebarOpen]
  );

  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(true);

  const handleFiltersSidebarOpen = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(true),
    [setIsFiltersSidebarOpen]
  );

  const handleFiltersSidebarClose = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(false),
    [setIsFiltersSidebarOpen]
  );

  const selectedLifestylesCount = size(lifestylesSet?.selectedLifestyles);

  return (
    <Fragment>
      {isFiltersSidebarOpen ? (
        <SelectLifestylesContentFiltersSidebar
          lifestylesFilters={lifestylesFilters}
          withPremiumContentAtFilter={withPremiumContentAtFilter}
          changeLifestylesFilters={handleChangeLifestylesFilters}
          onClose={handleFiltersSidebarClose}
        />
      ) : null}
      <MainScrollWrapper>
        <div className="flex-1 z-0">
          <div className="pt-6 pb-8">
            <div className="flex flex-col space-y-8">
              <SelectLifestylesContentHeader
                lifestylesFilters={lifestylesFilters}
                changeLifestylesFilters={handleChangeLifestylesFilters}
                showFiltersButton={!isFiltersSidebarOpen}
                onFiltersOpen={handleFiltersSidebarOpen}
                clearLifestylesFilters={clearLifestylesFiltersPersistInitial}
                filterLifestyles={filterLifestyles}
                lifestylesTotalCount={lifestylesTotalCount}
              />
              {lifestylesFetched && isEmpty(lifestyles) ? (
                <NoResults
                  noResultsI18nText={words.thereAreNoMatchesForSelectedFilters}
                />
              ) : (
                <LifestylesList
                  lifestyles={lifestyles}
                  lifestylesFetched={lifestylesFetched}
                  lifestylesSetFetched={lifestylesSetFetched}
                  lifestylesError={lifestylesError}
                  lifestylesSetError={lifestylesSetError}
                  lifestylesIsPlaceholderData={lifestylesIsPlaceholderData}
                  lifestylesSetIsPlaceholderData={
                    lifestylesSetIsPlaceholderData
                  }
                  lifestylesTotalCount={lifestylesTotalCount}
                  lifestylesSet={lifestylesSet}
                  lifestylesCacheKeys={lifestylesCacheKeys}
                  lifestylesSetCacheKeys={lifestylesSetCacheKeys}
                  onSelectedLifestylesSidebarOpen={
                    handleSelectedLifestylesSidebarOpen
                  }
                  onSelectedLifestylesSidebarClose={
                    handleSelectedLifestylesSidebarClose
                  }
                  updateLifestylesSetCache={updateLifestylesSetCache}
                  updateLifestyleCache={updateLifestyleCache}
                  onLifestylesSelect={onLifestylesSelect}
                  togglePreventModalClose={togglePreventModalClose}
                />
              )}
              {lifestylesSetFetched && lifestylesSet ? (
                <SelectedLifestylesFloatingCounter
                  lifestylesSet={lifestylesSet}
                  lifestylesSetCacheKey={lifestylesSetCacheKey}
                  isOpen={isSelectedLifestylesSidebarOpen}
                  onOpen={handleSelectedLifestylesSidebarOpen}
                  onClose={handleSelectedLifestylesSidebarClose}
                  updateLifestylesSetCache={updateLifestylesSetCache}
                  onLifestylesSelect={onLifestylesSelect}
                />
              ) : null}
              {hasNextLifestylesPage && (
                <LoadMoreButtonHelper
                  action={
                    LifestylesPermissions.READ_LIFESTYLES_LOAD_MORE_BUTTON_ITEMS_COUNT
                  }
                  itemsTotalCount={lifestylesTotalCount}
                  page={lifestylesPage}
                  limit={lifestylesLimit}
                  loadMoreItems={loadMoreLifestyles}
                  itemsFetchingNextPage={lifestylesFetchingNextPage}
                />
              )}
            </div>
          </div>
        </div>
      </MainScrollWrapper>
      {isSelectedLifestylesSidebarOpen ? (
        <SelectLifestylesContentSidebar
          lifestylesSet={lifestylesSet}
          lifestylesSetFetched={lifestylesSetFetched}
          lifestylesSetError={lifestylesSetError}
          lifestylesSetIsPlaceholderData={lifestylesSetIsPlaceholderData}
          lifestylesCacheKeys={lifestylesCacheKeys}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
          updateLifestylesSetCache={updateLifestylesSetCache}
          onClose={handleSelectedLifestylesSidebarClose}
          togglePreventModalClose={togglePreventModalClose}
          selectedLifestylesCount={selectedLifestylesCount}
        />
      ) : null}
    </Fragment>
  );
}

export default SelectLifestylesContent;
