import React, { useCallback, useMemo } from 'react';
import size from 'lodash/size';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsFilters
} from '../../../materialsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { words } from '../../../../../locales/keys';

interface MaterialsMyLibraryFilterProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

function MaterialsMyLibraryFilter({
  materialsFilters,
  changeMaterialsFilters
}: MaterialsMyLibraryFilterProps) {
  const currentUser = useCurrentUser();

  const isMyLib = useMemo<boolean>(() => {
    return size(materialsFilters?.clientIds) === 1
      ? materialsFilters.clientIds.includes(currentUser.id)
      : false;
  }, [currentUser.id, materialsFilters.clientIds]);

  const handleChange = useCallback<() => void>(() => {
    if (isMyLib) {
      changeMaterialsFilters({ nda: false }, ['clientIds']);
    } else {
      changeMaterialsFilters({ clientIds: [currentUser.id] }, ['nda']);
    }
  }, [changeMaterialsFilters, isMyLib, currentUser.id]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={words.myLibrary} />
        </h6>
      </div>

      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showMyLibrary}
        checked={isMyLib}
        onChange={handleChange}
      />
    </div>
  );
}

export default MaterialsMyLibraryFilter;
